import store from '@/store'

export function getFormAddCategory() {
    return {
        buttons: [
            {
                textButton: 'Volver',
                color: 'secondary',
                event: 'back',
                style: 'margin: 10px',
                active: true
            },
            {
                textButton: 'Crear',
                color: 'success',
                event: 'add',
                style: 'margin: 10px',
                active: store.state.isAdmin
            },
        ],
        rows: [
            {
                style: '',
                class: '',
                content: [
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'name',
                            label: 'Nombre',
                            maxLength: 255,
                            specialCharactersAllowed: false,
                            allowSpaces: true,
                            error: ''
                        },
                    },
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'psy_name',
                            label: 'Nombre técnico',
                            maxLength: 255,
                            specialCharactersAllowed: false,
                            allowSpaces: true,
                            error: ''
                        },
                    },
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'select',
                            value: '',
                            key: 'type',
                            label: 'Tipo de categoría',
                            maxLength: 30,
                            options: [],
                        },
                    },
                ]
            },
        ],
    }
}

export function getFormEditCategory() {
    return {
        buttons: [
            {
                textButton: 'Cancelar',
                color: 'danger',
                event: 'cancel',
                style: 'margin: 10px',
                active: true
            },
            {
                textButton: 'Aceptar',
                color: 'success',
                event: 'edit',
                style: 'margin: 10px',
                active: store.state.isAdmin
            },
        ],
        rows: [
            {
                style: '',
                class: '',
                content: [
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'name',
                            label: 'Nombre',
                            maxLength: 255,
                            specialCharactersAllowed: false,
                            allowSpaces: true,
                            error: ''
                        },
                    },
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'psy_name',
                            label: 'Nombre técnico',
                            maxLength: 255,
                            specialCharactersAllowed: false,
                            allowSpaces: true,
                            error: ''
                        },
                    },
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'select',
                            value: '',
                            key: 'type',
                            label: 'Tipo de categoría',
                            maxLength: 30,
                            options: [],
                        },
                    },
                ]
            },
        ],
    }
}